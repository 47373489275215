import React from 'react'
import PageLayout from '../components/PageLayout';
import styled from 'styled-components';
import GalleryItem from '../components/Gallery/GalleryItem';
import GalleryGrid from '../components/Gallery/GalleryGrid';
import { GatsbyImgFlip } from '../components/ImgComponents/imgComponents';
import { graphql } from 'gatsby';
import { centerizeBody } from '../utils/commonCSS';
import { MDXRenderer } from "gatsby-mdx";

function Team(props) {
  const memberList = props.data.markdownRemark.frontmatter.memberList;
  const pastMemberCodeBody = props.data.mdx.code.body;
  const items = memberList
    .sort((a, b) => (new Date(a.date) - new Date(b.date)))
    .map(member => {
      const { profileImage, name, id }= member
      return (
        <GalleryItem 
          key={id}
          imgComponent={<GatsbyImgFlip
            fluid={profileImage.childImageSharp.fluid}
            text={name}
          />}
        />
      )
    })
  return (
    <PageLayout title="Our Team" uppercaseTitle titleAnim>
      <div className={props.className}>
        <GalleryGrid>
          {items}
        </GalleryGrid>
        <MDXRenderer>{pastMemberCodeBody}</MDXRenderer>
      </div>
    </PageLayout>
  )
}

export default styled(Team)`
  ${centerizeBody}
`;

export const query = graphql`
  query {
    markdownRemark(fileAbsolutePath: {glob: "**/src/data/team/memberInfo.md"}) {
      frontmatter {
        memberList {
          id
          name
          date
          profileImage {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    },
    mdx(fileAbsolutePath: {glob: "**/src/data/team/pastTeam.mdx"}) {
      code {
        body
      }
    }
}`;
